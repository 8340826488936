import { LoginBackground } from '@/components/login'
import { OrganizationSelect } from '@/components/organization'
import { cls } from '@/lib/cls'
import { Person } from '@/model/auth/person'
import { OrganizationListResponse } from '@/model/selfcare/types'
import Head from 'next/head'
import Link from 'next/link'
import { ContemberHorizontal } from './logos'

export interface LayoutBaseProps {
	title?: string
	children: React.ReactNode
}

interface LayoutProps extends LayoutBaseProps {
	sectionName: React.ReactNode
	action?: React.ReactNode
	person?: Person
	selfcareOrganizations?: OrganizationListResponse['organizations']
}

const LayoutHeader = ({ selfcareOrganizations }: { selfcareOrganizations?: OrganizationListResponse['organizations'] }) => {
	return (
		<header className={'flex justify-between items-center py-2 pl-2 pr-4 bg-neutral-900 text-white'}>
			<div className={'flex items-center'}>
				<Link href="/">
					<ContemberHorizontal className="h-14 w-auto" />
				</Link>
				{selfcareOrganizations && selfcareOrganizations?.length > 1 ? (
					<OrganizationSelect organizations={selfcareOrganizations} />
				) : (
					selfcareOrganizations?.map(org => (
						<Link key={org.slug} href={`/${org.slug}/projects`}>
							{org.name}
						</Link>
					))
				)}
			</div>
			<Link href="/logout" className={cls('no-underline', 'hover:underline')}>
				Logout
			</Link>
		</header>
	)
}

export const Layout = ({ children, title, sectionName, action, selfcareOrganizations }: LayoutProps) => {
	return (
		<>
			<Head>
				<title>{title ?? 'AI-assisted Contember Studio'}</title>
			</Head>

			<LayoutHeader selfcareOrganizations={selfcareOrganizations}/>

			<div className="min-h-screen flex flex-col overflow-hidden pt-10 sm:pt-18">
				<main className="max-w-4xl w-full h-full px-5 pb-0 flex flex-grow flex-col sm:flex-row gap-4 items-stretch mx-auto relative">
					<section className="flex flex-col flex-grow">
						<header className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center mb-0 md:mb-6 py-5 md:py-8 gap-y-6 sm:gap-y-0">
							<h2 className="font-wallop font-semibold text-2xl sm:text-4xl">{sectionName}</h2>
							<div>{action}</div>
						</header>
						{children}
					</section>
				</main>
			</div>
		</>
	)
}

export const WideLayout = ({ children, title }: LayoutBaseProps) => {
	return (
		<>
			<Head>
				<title>{title ?? 'AI-assisted Contember Studio'}</title>
			</Head>

			<LayoutHeader selfcareOrganizations={undefined} />

			<div className="w-full min-h-screen bg-neutral-100">
				<main className="w-full px-2 sm:px-5 pb-0 items-stretch mx-auto relative min-h-screen">
					<section className="flex flex-col flex-grow">{children}</section>
				</main>
			</div>
		</>
	)
}

export const LayoutCenter = ({ children, title }: LayoutBaseProps) => {
	return (
		<>
			<Head>
				<title>{title ?? 'AI-assisted Contember Studio'}</title>
			</Head>
			<header className="fixed w-full flex justify-between items-center pr-4 bg-neutral-900 text-white">
				<Link href="/">
					<ContemberHorizontal className="h-14 w-auto" />
				</Link>
				<Link href="/logout" className={cls('no-underline', 'hover:underline')}>
					Logout
				</Link>
			</header>
			<div className="w-full min-h-screen flex items-center py-5 sm:py-10 bg-neutral-100">
				<main className="w-full max-w-4xl px-2 sm:px-5 pb-0 items-stretch mx-auto relative">
					<section className="flex flex-col flex-grow">{children}</section>
				</main>
			</div>
		</>
	)
}

export const LayoutAuth = ({ children, title }: LayoutBaseProps) => {
	return (
		<>
			<Head>
				<title>{title ?? 'AI-assisted Contember Studio'}</title>
			</Head>
			<header className="absolute l-0 t-0 w-full py-2 pl-2 pr-4 text-white sm:text-neutral-900">
				<ContemberHorizontal className="h-14 w-auto" />
			</header>
			<div className="sm:flex h-screen text-white sm:text-neutral-900">
				<div className="w-full flex-0 sm:w-1/2 px-4">
					<div className="flex items-center justify-center h-screen flex-col gap-12">{children}</div>
				</div>
				<div className="fixed top-0 left-0 w-full flex-0 -z-10 sm:w-1/2 h-screen overflow-hidden sm:relative">
					<LoginBackground className="min-w-full min-h-full w-auto h-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
				</div>
			</div>
		</>
	)
}
